.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hexagon {
  position: relative;
  width: 50px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.2);
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  margin: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: inherit;
  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
}

@media (max-width: 600px) {
  .hexagon {
    width: 40px;
    height: 40px;
    font-size: 1.2rem;
  }
}

.ripple {
  position: absolute;
  width: 80px;
  height: 80px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation: ripple-animation 0.5s ease-out forwards;
}

@keyframes ripple-animation {
  from {
    transform: translate(-50%, -50%) scale(0.5);
    opacity: 0.8;
  }
  to {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }
}
